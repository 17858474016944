import React,{useState} from 'react'
// import CalorexIcon from '../../../components/svg/calorex_icon'
import { usePrismCalentadores } from '../../../hooks/use-prism-calentadores'
// import { ComboBoxSemicircle,ComboBox_Semicircle_types } from '../../../components/buttons/_button_combobox';
import ProdutcsCarousel from './produtcs_carousel'
// import SemiArrow from '../../../components/svg/commons/semi_arrow'
// import HeaderTitle from '../../../components/_titles/_header_title'
import DetailTitle from '../../../content/model_detail/detail_logo'
import Dropdown from '../../../components/_comparator/dropdown'
import MenuUI from '../../../components/ui/menu_ui'; 

function HomeProducts() {
    const calentadoresQuery = usePrismCalentadores()
    
    const optionsFamily = [
        {
            uid: 'c-deposito',
            data: {
                title: {
                    text: 'depósito',
                    index: 'deposito'
                }
            }
        },
        {
            uid: 'c-de-paso',
            data: {
                title: {
                    text: 'de paso',
                    index: 'de-paso'
                }
            }
        },
        {
            uid: 'c-instantaneos',
            data: {
                title: {
                    text: 'instantáneo',
                    index: 'instantaneos'
                }
            }
        },
        {
            uid: 'c-solares',
            data: {
                title: {
                    text: 'solar',
                    index: 'solares'
                }
            }
        },
        {
            uid: 'electricos',
            data: {
                title: {
                    text: 'eléctrico',
                    index: 'electricos'
                    
                }
            }
        },
        {
            uid: 'c-comerciales',
            data: {
                title: {
                    text: 'comerciales',
                    index: 'comerciales'
                }
            }
        },
        
    ]
   
    const [product, changeProduct] = useState('deposito')
    const [defaultProduct, changeDefaultProduct] = useState('depósito')

    function onChangeProduct(item) {
        changeProduct(item.data.title.index);
        changeDefaultProduct(item.data.title.text)
    }


    return (
        <div className="home__produtcs--container">
            
            <DetailTitle _name='Familia Calorex' _title='Nuestros productos'></DetailTitle> 
            
            <Dropdown default_item={defaultProduct} items={optionsFamily} handleItemClick={onChangeProduct} type={2} />
            <MenuUI _items={optionsFamily} _callback={onChangeProduct}/>
            <div className="products__carousel">
                <ProdutcsCarousel calentadoresQuery={calentadoresQuery} _product={product} />
            </div>
        </div>

    )
}

export default HomeProducts
